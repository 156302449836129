<template>
  <div>
    <div class="wrapper">
      <div class="main shadow">
        <img
          class="img-fluid"
          src="../../assets/phobby404.png"
          alt="404 image"
        />
        <h3 class="text-blue">Page Not Found</h3>
        <p class="w-500">
          The page you are looking for might have been removed, <br />
          had its name changed or is temporary unavailable.
        </p>
        <div>
          <button class="mybtn m-3" @click="goHome">homepage</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
  mounted() {
    this.$store.commit("setCurrentNav", "404");
  },
  methods: {
    goHome() {
      let type = this.$store.state.login.clientType;
      if (type === "prepaid") {
        this.$store.commit("setCurrentNav", "Home");
        this.$router.push("prepaid-home");
      } else {
        this.$store.commit("setCurrentNav", "Home");
        this.$router.push("home");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  text-align: center;
  margin-top: 100px;
}

h3 {
  color: #2b94ca;
}

.main {
  padding: 0px 10% 2% 10%;
  padding-top: 20px;
  border-radius: 10px;
  background: #fff;
  display: inline-block;
  margin-top: -3em;
}
</style>
